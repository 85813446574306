$color_1: #e6e2af;
$color_2: #666;
$color_3: #14bff4;
$color_4: #002f2f;
$color_5: #e9e2af;
$color_6: #7da792;
$color_7: #a7a37e;
$background_color_1: #e6e2af;
$background_color_2: #efecca;
$background_color_3: #013a45;
$background_color_4: #046380;
$background_color_5: #057699;
$background_color_6: transparent;
$background_color_7: #024957;
$border_color_1: #a7a37e;
$border_color_2: #536956;
$border_color_3: #002f2f;
$border_color_4: #e9e2af;

.email_input, .form-control {
	height: 30px;
	background: #046380;
	color: $color_1;
	border: 1px solid;
	border-radius: 3px;
	border-color: $border_color_1;
	padding-left: 10px;
	font-size: 12pt;
	float: left;
	margin-right: 10px;
}
textarea.form-control {
	&::placeholder {
		color: $color_1;
		opacity: 0.5;
	}
	&::-webkit-input-placeholder {
		color: $color_1;
		opacity: 0.5;
	}
	&:-moz-placeholder {
		color: $color_1;
		opacity: 0.5;
	}
	&::-moz-placeholder {
		color: $color_1;
		opacity: 0.5;
	}
	&:-ms-input-placeholder {
		color: $color_1;
		opacity: 0.5;
	}
}
.project_title {
	height: 44px;
}
.project_icon {
	width: 100% !important;
  height: 100%;
}
dl {
	padding: 0.5em;
	margin-top: 20px;
}
dt {
	float: left;
	clear: left;
	width: 110px;
	text-align: right;
	font-weight: bold;
	display: block;
	&::after {
		content: ":";
	}
}
dd {
	margin: 0 0 0 120px;
	padding: 0 0 0.5em 0;
}
#dd_engines {
	min-height: 60px;
}
.project_text {
	z-index: 1;
}
.project_buttons {
	display: inline-block;
}
.project_button {
	display: inline-block;
	white-space: nowrap;
	vertical-align: top;
	margin-bottom: 10px;
}
.project_button_img {
	display: -webkit-flex; /* Safari */
    -webkit-align-items: center; /* Safari 7.0+ */
    display: flex;
    align-items: center;
	img {
		width: 128px;
		margin-top: 5px;
		display: block;
    	margin-left: auto;
    	margin-right: auto;
	}
}
.project_links {
	display: inline-block;
	float: right;
	white-space: nowrap;
	padding-top: 10px;
	padding-bottom: 10px;
	a {
		margin-right: 10px;
	}
}
.screenshot_list {
	height: 150px;
	margin-top: 10px;
	padding-bottom: 10px;
	overflow-x: auto;
	overflow-y: hidden;
	white-space: nowrap;
}
.screenshot {
	vertical-align: top;
	height: 100%;
	width: auto;
	margin-left: 10px;
}
blockquote {
	display: block;
	border-width: 0px 0;
	border-style: solid;
	border-color: $border_color_2;
	padding: 0.5em 0 0.5em;
	position: relative;
	&:before {
		position: absolute;
		top: 0em;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 3rem;
		height: 2rem;
		color: $color_2;
		text-align: center;
	}
	&:after {
		content: "\2013 \2003" attr(cite);
		display: block;
		text-align: right;
		font-size: 0.875em;
	}
}
.stars {
	height: 16px;
	margin-bottom: 12px;
	margin-top: -20px;
}
.stars-background {
	float: left;
	height: 100%;
	width: 80px;
	margin-top: 4px;
	margin-right: 8px;
	background: url(../../img/stars-background.svg) no-repeat;
}
.stars-foreground {
	height: 100%;
	background: url(../../img/stars-foreground.svg) no-repeat;
}
.blackout {
	color: transparent;
	background: url(../../img/blackout.svg);
}
.toprated {
	color: $color_3;
	text-transform: uppercase;
	font-size: 13px;
	margin: 4px;
}
.highlighted {
	color: $background_color_2;
	font-weight: bold;
}
#searchbox {
	width: calc(100% - 32px);
	float: left;
	height: 30px;
	background: #046380;
	color: $color_1;
	border: 1px solid;
	border-radius: 3px;
	border-color: $border_color_1;
	font-size: 12pt;
	&:focus {
		box-shadow: none;
	}
}
.icon-search {
	vertical-align: middle;
	width: 16px;
}
.thumbnail {
	min-height: 450px;
	background-color: $background_color_1;
	border-color: $border_color_3;
	&:hover {
		background-color: $background_color_2;
	}
	h4 {
		color: $color_4;
	}
	.description {
		color: $color_4;
	}
}
.filter-item {
	a {
		&:hover {
			text-decoration: none;
		}
		&:focus {
			text-decoration: none;
		}
	}
}
.well {
	background-color: $background_color_3;
	border: 0px;
	legend {
		color: $color_5;
		border-color: $border_color_4;
		padding-right: 20px;
	}
	a {
		text-align: right;
		position: absolute;
		left: 0px;
		width: calc(100% - 70px);
		margin-left: 35px;
		margin-right: 35px;
	}
}
.checkbox-label {
	display: block;
	position: relative;
	cursor: pointer;
	font-size: 15px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		&:checked {
			&~.checkmark {
				background-color: $background_color_4;
				&:after {
					display: block;
				}
			}
		}
	}
	&:hover {
		input {
			&~.checkmark {
				background-color: $background_color_5;
			}
		}
	}
	.checkmark {
		&:after {
			left: 3px;
			top: 0px;
			width: 5px;
			height: 9px;
			border: solid #e6e2af;
			border-width: 0 2px 2px 0;
			-webkit-transform: rotate(45deg);
			-ms-transform: rotate(45deg);
			transform: rotate(45deg);
		}
	}
}
.checkmark {
	position: absolute;
	top: 4px;
	left: 0;
	height: 13px;
	width: 13px;
	background-color: $background_color_4;
	border: 1px solid;
	border-radius: 3px;
	border-color: $border_color_1;
	&:after {
		content: "";
		position: absolute;
		display: none;
	}
}
.checkbox-text {
	display: block;
	margin-left: 2px;
}
input {
	&::-webkit-input-placeholder {
		color: $color_6;
	}
	&:-moz-placeholder {
		color: $color_6 !important;
	}
	&::-moz-placeholder {
		color: $color_6 !important;
	}
	&:-ms-input-placeholder {
		color: $color_6 !important;
	}
}
.per-page {
	select {
		background-color: $background_color_6;
	}
	width: 50px;
	height: 16px;
	background: url(../img/select.svg) no-repeat right transparent;
	border: 1px solid #e6e2af;
	border-radius: 3px;
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	option {
		background-color: $background_color_7;
		width: 50px;
	}
}
#pagination {
	a {
		background-color: $background_color_1;
		color: $color_4;
		border: 1px solid #002f2f;
		&:hover {
			background-color: $background_color_2;
		}
	}
	.active {
		a {
			background-color: $background_color_2;
		}
	}
}
.per_page_container {
	margin-bottom: 16px;
}
.noscript {
	border: 1px solid #e6e2af;
	font-size: 24px;
	margin: 100px;
	padding: 50px;
}
.project_description {
	table {
		width: 100%;
		border: 1px solid #a7a37e;
	}
	td {
		padding-top: 7px;
		padding-bottom: 7px;
		padding-left: 10px;
		padding-right: 10px;
		text-align: left;
		vertical-align: top;
	}
	@media (max-width: 370px) {
		td {
			font-size: 14px;
		}
	}
	@media (max-width: 300px) {
		td {
			font-size: 12px;
		}
	}
}
.lead {
	code {
		background: #013a45;
		border: none;
		color: $color_7;
	}
	.highlight {
		background: #013a45;
		border: none;
	}
}
.game-post-tile {
	float: left;
	padding: 10px;
	width: 50%;
	@media (max-width: 990px) {
		width: 100%;
	}
}
.float-left {
	float: left;
}
.float-right {
	float: right;
}
.game-post-description {
	margin-top: -8px;
	min-height: 100px;
	a {
		margin-left: 5px;
		margin-right: 5px;
	}
}
.game-post-details {
	margin-top: 20px;
}
@media (max-width: 400px) {
	dl {
		font-size: 14px;
	}
	dt {
		width: 80px;
	}
	dd {
		margin: 0 0 0 90px;
	}
	#dd_engines {
		min-height: 50px;
	}
}
@media (max-width: 315px) {
	dl {
		font-size: 12px;
	}
	dt {
		width: 70px;
	}
	dd {
		margin: 0 0 0 80px;
	}
	#dd_engines {
		min-height: 40px;
	}
}
.btn-icon {
	font-size: 48px;
	.network-name {
		display: none;
	}
}

#tumblr-date {
	float: right;
	opacity: 0.5;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-track {
  background: $color_2;
}
::-webkit-scrollbar-thumb {
  background: $color_1;
}
::-webkit-scrollbar-thumb:hover {
  background: $background_color_2;
}
